<template>
  <div class="general-report-by-flat" style="height: 100%">
    <v-menu
        v-model="showExpand"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y
        class="non-printable"
    >
      <v-list nav dense min-width="140"
              class="non-printable"
      >
        <v-list-item @click="toggleExpand(-1)">
          <v-list-item-title>Усі рівні</v-list-item-title>
        </v-list-item>
        <v-list-item
            v-for="(item, index) in max_lvl+1"
            :key="`index-menu-${index}`"
            @click="toggleExpand(item-1)"
        >
          <v-list-item-title>{{ `Рівень ${item-1}` }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        v-model="setting_dialog"
        temporary
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2 pb-2">
              <v-tabs fixed-tabs color="grey darken-2">
                <v-tab>Основні</v-tab>
                <v-tab>Групування</v-tab>
                <v-tab-item class="mt-3">
                  <v-row>
                    <v-col cols="12">
                      <Bank :value="filters.bank_id" @autocompleteChange="onBankChange"/>
                    </v-col>
                    <v-col cols="12" md="6">
                      <date-component v-model="filters.pay_date_from" label="Дата оплати з" clearable/>
                    </v-col>
                    <v-col cols="12" md="6">
                      <date-component v-model="filters.pay_date_to" label="Дата оплати по" clearable/>
                    </v-col>
                    <v-col cols="12">
                      <AddressElementSelect v-model="filters.city"
                                            label="Населений пункт" filled
                                            select_type="city"
                      />
                    </v-col>
                    <v-col cols="12">
                      <AddressElementSelect :parent_id="filters.city" v-model="filters.streets"
                                            label="Вулиця(-ки)" multiple filled
                                            select_type="street" use_parent_id
                                            :disabled="!filters.city"
                      />
                    </v-col>
                    <v-col cols="12">
                      <AddressElementSelect :parent_id="filters.streets" v-model="filters.buildings"
                                            label="Будинок(-ки)" multiple filled
                                            select_type="building" use_parent_id
                                            :disabled="!filters.streets.length"
                      />
                    </v-col>
                    <v-col cols="12">
                      <Checker :value="filters.checker" @autocompleteChange="onCheckerChange"/>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item class="mt-3">
                  <v-row>
                    <v-col cols="12">
                      <v-card
                          v-for="(item, idx) in grouping"
                          :key="`gr-idx-${idx}`"
                          style="display: flex; align-items: center"
                          class="ma-2 py-0"
                          elevation="3"
                      >
                        <div style="flex: 0 0 60px">
                          <v-checkbox color="success" class="ml-3" v-model="item.on"/>
                        </div>
                        <div style="flex: 1; font-weight: 400; font-size: .96rem">
                          {{ item.text }}
                        </div>
                        <div style="flex: 0 0 80px">
                          <v-btn icon @click="pushUp(item)">
                            <v-icon>mdi-arrow-up</v-icon>
                          </v-btn>
                          <v-btn icon @click="pushDown(item)">
                            <v-icon>mdi-arrow-down</v-icon>
                          </v-btn>
                        </div>
                      </v-card>
                    </v-col>

                  </v-row>
                </v-tab-item>
              </v-tabs>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="page page-a4-landscape-auto" style="height: auto !important; min-height: 215mm;"
         :style="`zoom: ${scale / 100}`">
      <div class="report" :style="`transform: scale(${zoom}); transform-origin: top left;`"
           style="height: 100%">
        <table style="width: 100%;">
          <caption>
            <div class="report-section report-header" style="padding-top: 2px; flex-wrap: wrap;">
              <div class="rp-col-12 header-underline">
                <div class="rp-row rp-text-center" style="font-size: 1.1rem; font-weight: 500">
                  {{ organization.full_name }} (Оплати в розрізі послуг та дат)
                </div>
              </div>
              <div class="rp-col-12"></div>
              <div class="rp-col-12 text-center mt-2">
                {{ print_data.organization_details_with_account }}
              </div>
              <div class="rp-col-12 text-left mt-8">
                Дата формування: {{ getCurrentDate() | formatDate }} {{ getPrintDateRange(date_start, date_end) }}
              </div>
            </div>
            <div class="empty-row report-header" style="height: 20px;"></div>
          </caption>
          <thead id="header">
            <tr>
              <th rowspan="2" style="min-width: 370px;">Послуга / Адреса / Дата</th>
              <template
                  v-for="(col, col_idx) in cols"
              >
                <th :key="`col-header_1-${col_idx}`" style="min-width: 60px; width: 60px; padding: 0;">
                  <div>{{ col.name }}</div>
                </th>
              </template>
              <th rowspan="2" style="min-width: 110px; width: 110px">Всього</th>
            </tr>
          </thead>

          <tbody v-for="(item, idx) in items"
                 :key="item.index"
                 :class="`${item.items ? item.class + ' details' : item.class}`"
                 :style="`${item.items ? 'display: none;' : ''}`"
                 :data-level="`level-${item.level}`"
                 :data-button="`button-${idx}`"
          >
          <template v-if="!item.items && by_grouping">
            <tr :class="`bg-level-${item.level}`">
              <td class="grow" colspan="1"
                  :style="`padding-left: ${item.level === 0 ? 8 : item.level * 16}px`"
              >
                <v-icon size="14" class="mr-1" color="grey darken-3"
                        @click.stop="showRows(item.class, `button-${idx}`)"
                        :id="`button-${idx}`"
                >
                  {{ getCollapseIcon(item.level) }}
                </v-icon>
                {{ item.group_value }}
              </td>
              <template v-for="(col, col_idx) in cols">
                <td style="min-width: 90px; width: 90px" :key="`col-header-1-${idx}-${col_idx}`">
                  {{ item[`sum_${col.id}`] }}
                </td>
              </template>
              <td style="min-width: 90px; width: 90px">{{ item.sum }}</td>
            </tr>
          </template>
          <template v-else-if="!item.items && !by_grouping">
            <tr>
              <td style="min-width: 370px;">{{ `${item.city_name}, ${item.street_name}, ${item.building_type_name}` }}</td>
               <template
                   v-for="(col, col_idx) in cols"
               >
                 <td style="min-width: 90px; width: 90px" :key="`col-item-1-${idx}-${col_idx}`">
                   {{ item[`sum_${col.id}`] }}
                 </td>
               </template>
              <td style="min-width: 90px; width: 90px">{{ item.sum }}</td>
            </tr>
          </template>
          <template v-else>
            <tr
                v-for="subitem in item.items"
                :key="subitem.index"
            >
              <td style="min-width: 370px;">{{ subitem.building_type_name }}</td>
              <template
                  v-for="(col, col_idx) in cols"
              >
                <td style="min-width: 90px; width: 90px" :key="`${subitem.index}-${col_idx}`">
                  {{ subitem[`sum_${col.id}`] }}
                </td>
              </template>
              <td style="min-width: 90px; width: 90px">{{ subitem.sum }}</td>
            </tr>
          </template>
          </tbody>
          <tr class="bg-level-0" style="text-align: center">
            <td class="grow" colspan="1" style="font-weight: 500; text-align: right;">Всього: </td>
              <template
                  v-for="(col, col_idx) in cols"
              >
                <td style="min-width: 90px; width: 90px; text-align: center" :key="`col-footer-2-${col_idx}`">
                  {{ getTotal(`sum_${col.id}`) | formatNumber}}
                </td>
              </template>
            <td style="min-width: 90px; width: 90px">{{ getTotal('sum') | formatNumber}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import reportAPI from "@/utils/axios/reports"
import {ALERT_SHOW} from "@/store/actions/alert";
import {LANDSCAPE_ZOOM_WIDTH} from "@/utils/reports";
import {getFlatType} from "@/utils/icons"
import ReportHouseAccountingComponentMixin from "@/mixins/report_house_accounting";


export default {
  name: "pays_by_service_bank_and_date_general_report",
  mixins: [ReportHouseAccountingComponentMixin],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    Bank: () => import("@/components/autocomplite/Bank"),
    Checker: () => import("@/components/autocomplite/Checker"),
  },
  computed: {},
  data() {
    return {
      filters: {
        bank_id: null,
        city: null,
        streets: [],
        buildings: [],
        pay_date_from: null,
        pay_date_to: null,
        checker: null
      },
      zoom_width: LANDSCAPE_ZOOM_WIDTH,
      grouping: [
        { text: 'Послуга', value: 'service_id', on: true },
        { text: 'Дата оплати', value: 'pay_date', on: true },
        { text: 'Житлові/нежитлові приміщення', value: 'not_living', on: false },
        { text: 'Населений пункт', value: 'city_id', on: true },
        { text: 'Вулиця', value: 'street_id', on: true },
        { text: 'Вид житла', value: 'building_type', on: false }
      ],
      services_columns: {},
      items: [],
      cols: []
    }
  },
  methods: {
    generate_rows(items, max_lvl, by_grouping, cols) {
      this.items = Object.freeze(items)
      this.max_lvl = max_lvl
      this.by_grouping = by_grouping
      this.cols = Object.freeze(cols)
    },
    generate_xlsx_file() {
      if (this.date_start && this.date_end) {
        this.$emit('xlsx_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => item.value)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: Object.assign({}, this.filters, {grouping: grouping})
        }
        reportAPI.pays_by_service_bank_and_date_general_report_xlsx(payload)
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a');
              const file_name = `pays_by_service_bank_and_date_general_report_${this.formatDate(this.date_start, 'MM_YYYY')}_${this.formatDate(this.date_end, 'MM_YYYY')}.xlsx`
              link.href = url;
              link.setAttribute('download', file_name); //or any other extension
              document.body.appendChild(link);
              link.click();
              this.$emit('xlsx_loading_status', false)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('xlsx_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_xlsx')
            })
      }
    },
    generate_email_send() {
      if (this.date_start && this.date_end) {
        this.$emit('email_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => item.value)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          email: this.email_address,
          filters: Object.assign({}, this.filters, {grouping: grouping})
        }
        reportAPI.pays_by_service_bank_and_date_general_report_email(payload)
            .then(response => response.data)
            .then(data => {
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {color: data.status === 'OK' ? 'success' : 'error', text: data.text})
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_email')
            })
      }
    },
    generate_report() {
      if (this.date_start && this.date_end) {
        this.$emit('report_loading_status', true)
        const grouping = this.grouping.filter(i => i.on).map(item => item.value)
        const payload = {
          date_start: this.date_start,
          date_end: this.date_end,
          filters: Object.assign({}, this.filters, {grouping: grouping})
        }
        reportAPI.pays_by_service_bank_and_date_general_report(payload)
            .then(response => response.data)
            .then(data => {
              this.items = []
              this.cols = []
              this.expand = false
              this.by_grouping = false
              this.max_lvl = 0
              this.generate_rows(data.rows, data.max_level, data.by_grouping, data.cols)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_report', !!this.items)
              this.$emit('report_loading_status', false)
              this.$nextTick(() => {
                this.expand = false
                this.toggleExpand(this.max_lvl)
                this.getOnFullPage()
              })
            })
      } else {
        this.$store.commit(ALERT_SHOW, {text: 'Місяць - має бути заповнений', color: 'error'})
      }
    },
    getTotal(col) {
      let total = 0

      if (this.by_grouping) {
        total = this.items.filter(item => item.level === 0 && !item.items).reduce((acc, r) => {
          return acc + r[col]
        }, 0)
      } else {
        total = this.items.filter(item => !item.level).reduce((acc, r) => {
          return acc + r[col]
        }, 0)
      }
      return total
    },
    getOnFullPage() {
      let width = 12
      const first_row = document.getElementById('header')
      first_row.childNodes.forEach(item => width += (item.clientWidth || 0))
      const gen_element = document.getElementsByClassName('report')[0]
      const scrollWidth = width * this.zoom
      const client_width = gen_element.clientWidth * this.zoom
      let zoom_width = 0
      let zoom = client_width / scrollWidth
      if (zoom === 0) zoom = 1
      if (zoom > 1) {
        zoom = 1
        zoom_width = LANDSCAPE_ZOOM_WIDTH
      }

      if (zoom !== 1) {
        zoom_width = 1 / zoom * LANDSCAPE_ZOOM_WIDTH
      }

      this.zoom = zoom
      this.zoom_width = zoom_width
    },
    getFlatType,
    onBankChange(payload) {
      this.filters.bank_id = payload.value || null
    },
    onCheckerChange(payload) {
      this.filters.checker = payload.value || null
    },
  },
  created() {
    reportAPI.report_settings("pays_by_service_bank_and_date_general_report")
        .then(response => response.data)
        .then(data => {
          const server_filters = JSON.parse(data)
          Object.keys(this.filters).forEach(key => {
            if (server_filters[key] !== undefined) {
              this.filters[key] = server_filters[key]
            }
          })
          if (server_filters['grouping'] && this.grouping) {
            this.grouping.forEach(g => {
              g.on = !!server_filters.grouping.includes(g.value);
            })
          }
        })
  }
}
</script>

<style lang="scss" scoped>
$border-color: #cbcbcb;

table, th, td {
  border: 1px solid #cbcbcb;
  border-collapse: collapse;
}

thead {
  th {
    padding: 8px !important;
  }
}

th {
  background: #f3f3f3;
  font-weight: 500 !important;
}
th, td {
  padding: 5px;
  font-size: 10.6px;
  font-weight: 400;
  width: auto;
}
th.grow, td.grow {
  width: 180px;
  min-width: 180px;
}
tbody {
  tr {
    td {
      text-align: center;

      &:nth-child(1) {
        text-align: left;
        padding-left: 14px;
      }
    }
  }
}
:deep(td button:focus) {
  background: none !important;
}
.bg-level-0 {
  background: #e2e2e2;
}
.bg-level-1 {
  background: #f4f4f4;
}
.bg-level-2 {
  background: #f6f6f6;
}
.bg-level-3 {
  background: #f8f8f8;
}

.page-a4-landscape-auto {
  width: 1160px !important;
  position: relative;
}


@media print {
  * {
    float: none !important;
    clear: both;
  }

  .pages {
    height: auto !important;
    overflow: visible !important;
  }

  .page {
    visibility: visible !important;
    margin: 0 2px !important;
  }

  @page {
    margin: 0 0 5mm 0 !important;
  }
}

</style>